var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"card hidden_overflow_tabela px-2 mt-2"},[_c('validation-observer',[_c('b-form',[_c('b-row',{staticClass:"align-items-start"},[_c('b-col',{staticClass:"px-1",attrs:{"sm":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"CNPJ/CPF","label-class":"font_size_label","label-for":"identifier_company"}},[(_vm.showIdentifierCompanyMask)?_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.computedMask),expression:"computedMask"}],attrs:{"id":"identifier_company","placeholder":"00.000.000/0000-00","max-length":"18","autocomplete":"off"},model:{value:(_vm.form.identifier_company),callback:function ($$v) {_vm.$set(_vm.form, "identifier_company", $$v)},expression:"form.identifier_company"}}):_vm._e(),(_vm.errorApi.cnpj)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.errorApi.cnpj))]):_vm._e()],1)],1),_c('b-col',{staticClass:"px-1",attrs:{"sm":"12","lg":"6"}},[_c('company-input-filter',{attrs:{"form":_vm.form},model:{value:(_vm.form.company_name),callback:function ($$v) {_vm.$set(_vm.form, "company_name", $$v)},expression:"form.company_name"}})],1)],1),_c('b-row',{staticClass:"align-items-start"},[_c('b-col',{attrs:{"sm":"6","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Data de início","label-for":"data-input-inicio","label-class":"font_size_label"}},[_c('validation-provider',{attrs:{"name":"data de início","rules":{ date_format: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],staticClass:"form-control",attrs:{"id":"data-input-inicio","state":_vm.inputDateStart > 0 ? false:null,"placeholder":"00/00/0000","autocomplete":"off"},on:{"input":_vm.setDateStartInput},model:{value:(_vm.inputDateStart),callback:function ($$v) {_vm.inputDateStart=$$v},expression:"inputDateStart"}}),_c('b-input-group-append',[_c('b-form-datepicker',{staticClass:"form-control d-flex align-items-center",attrs:{"locale":"pt-BR","button-only":"","right":"","hide-header":true,"label-help":"Selecione a data de início","button-variant":"custom-blue","clearable":""},on:{"input":_vm.setDateStartPicker},model:{value:(_vm.form.date_start),callback:function ($$v) {_vm.$set(_vm.form, "date_start", $$v)},expression:"form.date_start"}})],1)],1),(errors && errors.length > 0)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"sm":"6","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Data de fim","label-for":"data-input-fim","label-class":"font_size_label"}},[_c('validation-provider',{attrs:{"name":"data de fim","rules":{ date_format: true, date_range: _vm.inputDateStart }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],staticClass:"form-control",attrs:{"id":"data-input-fim","state":_vm.inputDateEnd > 0 ? false:null,"placeholder":"00/00/0000","autocomplete":"off"},on:{"input":_vm.setDateEndInput},model:{value:(_vm.inputDateEnd),callback:function ($$v) {_vm.inputDateEnd=$$v},expression:"inputDateEnd"}}),_c('b-input-group-append',[_c('b-form-datepicker',{staticClass:"form-control d-flex align-items-center",attrs:{"locale":"pt-BR","button-only":"","right":"","hide-header":true,"label-help":"Selecione a data fim","button-variant":"custom-blue","clearable":""},on:{"input":_vm.setDateEndPicker},model:{value:(_vm.form.date_end),callback:function ($$v) {_vm.$set(_vm.form, "date_end", $$v)},expression:"form.date_end"}})],1)],1),(errors && errors.length > 0)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"lg":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Status","label-class":"font_size_label","label-for":"status"}},[_c('validation-provider',{attrs:{"name":"status"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"status","options":_vm.statusOptions,"placeholder":"Selecione o status","label":"descricao","item-text":"status","item-value":"status","clearable":false},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}},[(errors && errors.length > 0)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}])})],1)],1)],1),_c('b-row',{staticClass:"align-items-center"},[_c('b-col',{attrs:{"lg":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Unidade Operacional","label-class":"font_size_label","label-for":"unit"}},[_c('validation-provider',{attrs:{"name":"Unidade operacional"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"unit","options":_vm.operatingOptions,"placeholder":"Selecione a unidade operacional","label":"descricao_unidade","item-text":"descricao_unidade","item-value":"id_unidade","clearable":false},model:{value:(_vm.form.operating_unit),callback:function ($$v) {_vm.$set(_vm.form, "operating_unit", $$v)},expression:"form.operating_unit"}},[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}])})],1)],1)],1)],1)],1),_c('Filters',{attrs:{"disableButtonSearch":_vm.isSearchDisable},on:{"actionSearch":_vm.search,"actionClear":_vm.clear}}),_c('b-row',[_c('b-col',{staticClass:"col-12 mb-sm-0",attrs:{"lg":"5","md":"5","sm":"5"}},[_c('div',{staticClass:"d-flex justify-center-center align-items-center"},[_c('span',{staticClass:"mr-1",attrs:{"for":"tableSize"}},[_vm._v(" Mostrar ")]),_c('v-select',{staticClass:"customizeDifferentSpace",attrs:{"id":"tableSize","options":_vm.optionsSize,"clearable":false,"append-to-body":""},on:{"input":_vm.fireEventFilter},model:{value:(_vm.defaultSize),callback:function ($$v) {_vm.defaultSize=$$v},expression:"defaultSize"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Nenhuma opção selecionável.")])])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }