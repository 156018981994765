<template>
  <main class="card hidden_overflow_tabela px-2 mt-2">
    <validation-observer>
      <b-form>
        <b-row class="align-items-start">
          
          <b-col
              sm="12"
              lg="6"
              class="px-1"
          >

            <b-form-group
                label="CNPJ/CPF"
                label-class="font_size_label"
                label-for="identifier_company"
            >

              <b-form-input
                  v-if="showIdentifierCompanyMask"
                  id="identifier_company"
                  v-model="form.identifier_company"
                  v-mask="computedMask"
                  placeholder="00.000.000/0000-00"
                  max-length="18"
                  autocomplete="off"
              >
              </b-form-input>
              <small  v-if="errorApi.cnpj" class="text-danger">{{ errorApi.cnpj }}</small>

            </b-form-group>
          </b-col>

          <b-col
              sm="12"
              lg="6"
              class="px-1"
          >
            <company-input-filter
              v-model="form.company_name"
              :form="form"
            />
          </b-col>
        </b-row>

        <b-row class="align-items-start">
          <b-col
              sm="6"
              lg="3"
          >
            <b-form-group
                label="Data de início"
                label-for="data-input-inicio"
                label-class="font_size_label"
            >
              <validation-provider
                  #default="{ errors }"
                  name="data de início"
                  :rules="{ date_format: true }">
                <b-input-group>
                  <b-form-input
                      id="data-input-inicio"
                      v-model="inputDateStart"
                      v-mask="'##/##/####'"
                      class="form-control"
                      :state="inputDateStart > 0 ? false:null"
                      placeholder="00/00/0000"
                      autocomplete="off"
                      @input="setDateStartInput"
                  />
                  <b-input-group-append>
                    <b-form-datepicker
                        v-model="form.date_start"
                        class="form-control d-flex align-items-center"
                        locale="pt-BR"
                        button-only
                        right
                        :hide-header="true"
                        label-help="Selecione a data de início"
                        button-variant="custom-blue"
                        clearable
                        @input="setDateStartPicker"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small v-if="errors && errors.length > 0" class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
              sm="6"
              lg="3"
          >
            <b-form-group
                label="Data de fim"
                label-for="data-input-fim"
                label-class="font_size_label"
            >
              <validation-provider
                  #default="{ errors }"
                  name="data de fim"
                  :rules="{ date_format: true, date_range: inputDateStart }">
                <b-input-group>
                  <b-form-input
                      id="data-input-fim"
                      v-model="inputDateEnd"
                      v-mask="'##/##/####'"
                      class="form-control"
                      :state="inputDateEnd > 0 ? false:null"
                      placeholder="00/00/0000"
                      autocomplete="off"
                      @input="setDateEndInput"
                  />
                  <b-input-group-append>
                    <b-form-datepicker
                        v-model="form.date_end"
                        class="form-control d-flex align-items-center"
                        locale="pt-BR"
                        button-only
                        right
                        :hide-header="true"
                        label-help="Selecione a data fim"
                        button-variant="custom-blue"
                        clearable
                        @input="setDateEndPicker"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small v-if="errors && errors.length > 0" class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
              lg="6"
              sm="12"
          >
            <b-form-group
                label="Status"
                label-class="font_size_label"
                label-for="status"
            >
              <validation-provider
                  #default="{errors}"
                  name="status"
              >
                <v-select
                    id="status"
                    v-model="form.status"
                    :options="statusOptions"
                    placeholder="Selecione o status"
                    label="descricao"
                    item-text="status"
                    item-value="status"
                    :clearable="false"
                >
                  <small v-if="errors && errors.length > 0" class="text-danger">{{ errors[0] }}</small>
                </v-select>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="align-items-center">
          <b-col
              lg="6"
              sm="12"
          >
            <b-form-group
                label="Unidade Operacional"
                label-class="font_size_label"
                label-for="unit"
            >
              <validation-provider
                  #default="{errors}"
                  name="Unidade operacional"
              >
                <v-select
                    id="unit"
                    v-model="form.operating_unit"
                    :options="operatingOptions"
                    placeholder="Selecione a unidade operacional"
                    label="descricao_unidade"
                    item-text="descricao_unidade"
                    item-value="id_unidade"
                    :clearable="false"
                >
                  <small class="text-danger">{{ errors[0] }}</small>
                </v-select>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
    <Filters
        :disableButtonSearch="isSearchDisable"
        @actionSearch="search"
        @actionClear="clear"
    />
    <b-row>
      <b-col
          lg="5"
          md="5"
          sm="5"
          class="col-12 mb-sm-0"
      >
        <div class="d-flex justify-center-center align-items-center">
          <span
              class="mr-1"
              for="tableSize"
          >
            Mostrar
          </span>
          <v-select
              id="tableSize"
              v-model="defaultSize"
              :options="optionsSize"
              :clearable="false"
              class="customizeDifferentSpace"
              @input="fireEventFilter"
              append-to-body
          >
            <span slot="no-options">Nenhuma opção selecionável.</span>
          </v-select>
        </div>
      </b-col>
    </b-row>
  </main>

</template>

<script>

import {
  BButton,
  BCol, BForm,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BRow, BTable,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import moment from 'moment/moment'
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'
import Filters from '@/views/components/custom/filters/Filters.vue'
import tourSchedulingMixin from '../mixins/tourSchedulingMixin'
import CompanyInputFilter from '@/views/components/custom/empresa/CompanyInputFilter.vue';
import { actions, subjects } from '@/libs/acl/rules'

extend("date_range", {
  params: ["startDate"],
  message: "A data de início não pode ser maior que a data de fim",
  validate: (endDate, { startDate }) => {
    if (startDate == '' || endDate == '') return true;
    return moment(startDate, 'DD-MM-YYYY').isBefore(moment(endDate, 'DD-MM-YYYY'));
  }
});

extend("date_format", {
  message: "Informe uma data válida",
  validate: (value) => {
    const regex = /^\d{2}\/\d{2}\/\d{4}$/;
    if (!regex.test(value)) return false;

    const [day, month, year] = value.split("/").map(Number);
    const date = new Date(year, month - 1, day);

    return (
        date.getFullYear() === year &&
        date.getMonth() === month - 1 &&
        date.getDate() === day
    );
  }
});

export default {
  components: {
    BForm,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    BButton,
    BTable,
    BFormDatepicker,
    BInputGroup,
    BInputGroupAppend,
    vSelect,
    Filters,
    ValidationObserver,
    ValidationProvider,
    CompanyInputFilter,
  },
  mixins: [tourSchedulingMixin],
  props: {
    filter: {
      required: false,
      default: false,
    },
    errorApi: {
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      filterForm: true,
      form: {
        identifier_company: '',
        company_name: '',
        date_start: moment().startOf('year').format('DD-MM-YYYY'),
        date_end: moment().format('DD-MM-YYYY'),
        operating_unit: '',
        status: '',
        company_name_filter: 'nome_fantasia',
      },
      searchFilter: null,
      inputDateStart: moment().startOf('year').format('DD-MM-YYYY'),
      inputDateEnd: moment().format('DD-MM-YYYY'),
      statusOptions: ['Agendado', 'Finalizado', 'Cancelado'],
      operatingOptions: [],
      showIdentifierCompanyMask: true,
      defaultSize: 10,
      optionsSize: [10, 25, 50, 100],
      error: {
        cnpj: null
      }
    }
  },
  computed: {
    computedMask() {
      return this.form.identifier_company.length <= 11 ? '###.###.###-##' : '##.###.###/####-##'
    },
    isSearchDisable() {
      return (this.inputDateStart !== '' &&
             this.inputDateEnd !== '' &&
             !moment(this.inputDateStart, 'DD-MM-YYYY').isBefore(moment(this.inputDateEnd, 'DD-MM-YYYY')));
    }
  },
  mounted() {
    if(this.loadData){
      this.getUnits()
      this.start()
    }
  },
  methods: {
    setDateStartInput() {
      if (this.inputDateStart && this.inputDateStart.length == 0) {
        this.form.date_start = null
      } else if (this.inputDateStart && this.inputDateStart.length == 10) {
        this.form.date_start = moment(this.inputDateStart, 'DD/MM/YYYY').format('YYYY-MM-DD')
      } else {
        this.form.date_start = this.inputDateStart
      }
    },
    setDateEndInput() {
      if (this.inputDateEnd && this.inputDateEnd.length == 0) {
        this.form.date_end = null
      } else if (this.inputDateEnd && this.inputDateEnd.length == 10) {
        this.form.date_end = moment(this.inputDateEnd, 'DD/MM/YYYY').format('YYYY-MM-DD')
      } else {
        this.form.date_end = this.inputDateEnd
      }
    },
    setDateStartPicker() {
      this.inputDateStart = moment(this.form.date_start).format('DD/MM/YYYY')
    },
    setDateEndPicker() {
      this.inputDateEnd = moment(this.form.date_end).format('DD/MM/YYYY')
    },
    getUnits() {
      const parameters = {
        ativo: true,
      }

      const apiRoute = this.$can(actions.VISUALIZAR, subjects.AGENDAMENTO_AE) ? this.$api.getUnitiesByScheduling() : this.$api.unidade()

      this.$http.get(apiRoute, { params: parameters }).then(({ data }) => {
        this.operatingOptions = data
      })
    },
    start() {
      this.searchFilter = {
        route: this.$router.currentRoute.name,
        column_order: 'asc',
        nome_fantasia: this.form.company_name,
        company_name_filter: this.form.company_name_filter,
        cnpj: this.form.identifier_company,
        data_inicio: this.form.date_start,
        data_fim: this.form.date_end,
        unidade_operacional: this.form.operating_unit.id_unidade,
        status: this.form.status,
        filter: this.filter,
        porPagina: this.defaultSize,
      }
      this.$emit('filterTable', this.searchFilter)
    },
    fireEventFilter() {
      this.searchFilter = {
        route: this.$router.currentRoute.name,
        column_order: 'asc',
        nome_fantasia: this.form.company_name,
        company_name_filter: this.form.company_name_filter,
        cnpj: this.form.identifier_company,
        data_inicio: this.form.date_start,
        data_fim: this.form.date_end,
        unidade_operacional: this.form.operating_unit.id_unidade,
        status: this.form.status,
        filter: this.filter,
        porPagina: this.defaultSize,
        pagina: 1,
      }

      this.$emit('filterTable', this.searchFilter)
    },
    search() {
      this.searchFilter = {
        porPagina: this.defaultSize,
        route: this.$router.currentRoute.name,
        column_order: 'asc',
        nome_fantasia: this.form.company_name,
        company_name_filter: this.form.company_name_filter,
        cnpj: this.form.identifier_company,
        data_inicio: this.form.date_start,
        data_fim: this.form.date_end,
        unidade_operacional: this.form.operating_unit.id_unidade,
        status: this.form.status,
        pagina: 1,
      }

      this.$emit('filterTable', this.searchFilter)
    },
    clear() {
      this.showIdentifierCompanyMask = false
      this.form.company_name = ''
      this.form.company_name_filter = 'nome_fantasia'
      this.form.identifier_company = ''
      this.form.date_start = ''
      this.form.date_end = ''
      this.inputDateStart = ''
      this.inputDateEnd = ''
      this.form.operating_unit = ''
      this.form.status = ''

      this.$nextTick(() => {
        this.showIdentifierCompanyMask = true
        this.searchFilter = {
          defaultSize: this.defaultSize,
          route: this.$router.currentRoute.name,
          column_order: 'asc',
          nome_fantasia: this.form.company_name,
          company_name_filter: this.form.company_name_filter,
          cnpj: this.form.identifier_company,
          data_inicio: this.form.date_start,
          data_fim: this.form.date_end,
          unidade_operacional: this.form.operating_unit.id_unidade,
          status: this.form.status,
          porPagina: 10,
          pagina: 1,
        }

        this.$emit('filterTable', this.searchFilter)
      })
    },
  },
  watch: {
    filter() {
      this.start()
    },
    'errorApi.cnpj': function (cnpj) {
      if (cnpj !== undefined) {
        this.error.cnpj = cnpj
      }
    }
  }
}
</script>

